import React from "react";
import * as St from "./styles";
import Button from "components/Button";
import Layout from "components/OldLayout";
import themes from "styles";
import { useHistory } from "react-router-dom";


const WithDrawByTeuCard = () => {

  const history = useHistory();

    return(
    <St.SafeView>
        <Layout
        routeBack="/opcoes-acesso"
        title="Bem vindo ao Empréstimo Saque Rápido com cartão Teucard"
      >
        <St.Container>
        <St.ImageItem src={themes.components.png.teuCardCards}/>
        <St.MiddleContainer>
              <St.Rounded />
              <p style={{ width: "32vw", fontSize: "16px" }}>
                É essencial que você possua o{" "}
                <b> Limite de Saque </b>disponível!
              </p>
            </St.MiddleContainer>
            <St.TopSpace>
            <St.Footer>
              <Button
                buttonType="primary"
                text={"Avançar"}
                onClick={() => history.push("/confirm-data-by-teu-card")}
              />
            </St.Footer>
            </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
    )

}

export default WithDrawByTeuCard;